import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import './app-banner.component.scss'

/**
 * React App Banner component
 * @component
 * @example
 * const bannerData = {
 *  bannerData: {
 *    title: "Page title"
 *  },
 * }
 * return (
 *  <AppBannerComponent />
 * )
 */
const AppBannerComponent = ({
  bannerData
}) => {
  const smartBannerRef = useRef(null)
  const [showBanner, setshowBanner] = useState(true)

  // Function to close the App download banner
  const close = () => {
    smartBannerRef.current.remove()
    sessionStorage.removeItem('popUpName')
    sessionStorage.setItem('hideBanner', true)
  }

  // Function to check condition and show banner
  const showAppBanner = () => {
    let hideBanner = sessionStorage.getItem('hideBanner')

    if (
      !hideBanner
    ) {
      sessionStorage.setItem('popUpName', 'download-app-banner')
      setshowBanner(true)
    } else {
      setshowBanner(false)
    }
  }

  // Function to get Banner Details depending on the user language
  const getAppBannerDetails = (countryThemeSetting, language) => {
    if (language === "en") return countryThemeSetting?.countryThemeSettings?.appBanner

    let getLanguageNode =
      countryThemeSetting?.languageCountryThemeSetting?.languageCountryThemeSettings?.find(o => o.language === language)
    return getLanguageNode && getLanguageNode['appBanner']
  }

  useEffect(() => {
    showAppBanner()
    // setTimeout(showAppBanner, 3000)
  }, [])

  const appBannerData = getAppBannerDetails(bannerData.appbanner, bannerData.language)
  return (
    (showBanner && appBannerData && appBannerData.smartLink) ?
      <div ref={smartBannerRef} id="app-smart-banner" className="app-smart-banner pad-xs-all pad-sm-vert bk-action-light z-overlay">
        <div className="d-flex">
          <a id="app-close" href="#" className="d-flex align-items-center mar-xs-rt close-btn" amplitude-id="button-close" onClick={close}>
            <i data-grunticon-embed="" className="wu-icon icon-0022_close bg-cover close-img" data-wu-icon="icon-0022_close">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                <title>0022_close</title>
                <g id="0022_close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <path d="M28.4446875,28.4446875 C27.5857121,29.3036629 26.1930379,29.3036629 25.3340625,28.4446875 L16,19.110625 L16,19.110625 L6.6659375,28.4446875 C5.80696213,29.3036629 4.41428787,29.3036629 3.5553125,28.4446875 C2.69633713,27.5857121 2.69633713,26.1930379 3.5553125,25.3340625 L12.889375,16 L12.889375,16 L3.5553125,6.6659375 C2.69633713,5.80696213 2.69633713,4.41428787 3.5553125,3.5553125 C4.41428787,2.69633713 5.80696213,2.69633713 6.6659375,3.5553125 L16,12.889375 L16,12.889375 L25.3340625,3.5553125 C26.1930379,2.69633713 27.5857121,2.69633713 28.4446875,3.5553125 C29.3036629,4.41428787 29.3036629,5.80696213 28.4446875,6.6659375 L19.110625,16 L19.110625,16 L28.4446875,25.3340625 C29.3036629,26.1930379 29.3036629,27.5857121 28.4446875,28.4446875 Z" id="Path" fill="#000000"></path>
                </g>
              </svg>
            </i>
          </a>
          <div>
            <i data-grunticon-embed="" className="wu-icon icon-wu_logo wu-icon__mega bg-cover" data-wu-icon="icon-wu_logo">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="70px" height="70px" viewBox="0 0 100 100" version="1.1">
                <title>Western Union Logo</title>
                <g id="V4" stroke="none" strokeWidth="1" fill="#00000" fillRule="evenodd">
                  <g id="main-page-Copy" transform="translate(-489.000000, -102.000000)" fillRule="nonzero">
                    <g id="Group-6-Copy" transform="translate(489.000000, 102.000000)">
                      <g id="Group-10" fill="#000000">
                        <circle id="Oval" cx="50" cy="50" r="50"></circle>
                      </g>
                      <g id="WU_LOGO_PRIM_HZ_YL_RGB" transform="translate(20.000000, 38.000000)">
                        <g id="ARTWORK">
                          <g id="Group">
                            <polygon id="Path" fill="#FFFFFF" points="6.53398058 23.1969487 0.388349515 0.371507826 1.95631068 0.371507826 8.10194175 23.1969487">
                            </polygon>
                            <path d="M39.3203883,0.371507826 L33.2330097,23.1969487 L28.815534,23.1969487 L24.2038835,8.06419655 L19.592233,23.1969487 L15.2038835,23.1969487 L9.05825243,0.371507826 L13.8446602,0.371507826 L17.6067961,14.8157321 L22.0048544,0.371507826 L26.4320388,0.371507826 L30.907767,14.9247077 L34.5873786,0.371507826 L39.3203883,0.371507826 Z M53.3834951,0.371507826 L53.3834951,14.429364 L53.3834951,14.464038 C53.3834951,14.4838518 53.4805825,16.3958787 52.2718447,17.7085397 C51.4854369,18.560531 50.3058252,18.9914801 48.7621359,18.9914801 C47.2184466,18.9914801 46.038835,18.560531 45.2524272,17.7085397 C44.0436893,16.3958787 44.1407767,14.4838518 44.1407767,14.464038 L44.1407767,0.371507826 L42.1504854,0.371507826 L39.3203883,11.0015851 L39.3203883,14.305528 C39.3203883,17.1190806 40.2135922,19.5016842 41.9029126,21.2007133 C43.592233,22.894789 45.961165,23.7913612 48.7621359,23.7913612 C51.5582524,23.7913612 53.9320388,22.894789 55.6213592,21.2007133 C57.3106796,19.5066376 58.2038835,17.1190806 58.2038835,14.305528 L58.2038835,0.371507826 L53.3834951,0.371507826 Z" id="Shape" fill="#FFDD00"></path>
                            <polygon id="Path2" fill="#FFFFFF" points="10.868932 23.1969487 4.72330097 0.371507826 6.29126214 0.371507826 12.4368932 23.1969487">
                            </polygon>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </i>
          </div>
          <div className="pad-xs-lt banner-msg line-height-1rem">
            <strong className="d-inline-flex font-body-xs position-relative line-height-1rem d-block">{appBannerData.title}</strong>
            <span className="d-inline-flex font-body-sm font-weight-normal line-height-1rem">{appBannerData.description}</span>
          </div>
          <a id="app-download" href={appBannerData.smartLink} className="app-download p-0 d-flex align-items-center justify-content-center mar-sm-tp position-relative text-action-light wu-btn wu-btn--action-light-ghost wu-btn--dark wu-btn--round" data-linkname="banner-smartapp-install" rel="noreferrer" amplitude-id="button-download" target="_blank">
            {appBannerData.downloadButtonText}
          </a>
        </div>
      </div> :
      <></>
  )
}

AppBannerComponent.propTypes = {
  /**
   * page-data attributes
   */
  "bannerData": PropTypes.object
}

export default AppBannerComponent
