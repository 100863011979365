import { getCountryCodeAndLanguage,getWuPrepaidLink } from '../services/utility.service.js'
import { handleListItemClick } from '../services/ding-top-up.js'
/**
 * set the fullName of the user
 * @param {string} firstName first name of user
 * @param {string} lastName last name of user
 * @return fullname
 */
const setFullName = (
  firstName,
  lastName
) => {
  let fullName = `${firstName} ${lastName ? lastName : ``}`
  fullName = fullName.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
  if (
    (window.matchMedia(`(min-width: 992px) and (max-width: 1145px)`).matches) && fullName.length > 10
  ) {
    fullName = `${fullName.substring(0, 10)}...`
  } else if (
    (window.matchMedia(`(min-width: 1145px`).matches) && fullName.length > 20
  ) {
    fullName = `${fullName.substring(0, 20)}...`
  }
  return fullName
}

/**
 * change the href of headers for logged in state
 */
const changeHrefForLoggedInState = () => {
  const navItem = document.querySelectorAll(`a[data-href]`)
  for (
    let element of navItem
  ) {
    element.setAttribute(`href`, element.getAttribute(`data-href`))
  }
}

const toggleHideShowMenuItems = () => {
  const disableMenuItem = document.querySelectorAll(`li[disable-menu-item="1"]`)
  for (let icount of disableMenuItem) {
    (window.sessionStorage.AccountNumber) ? icount.classList?.remove('d-none') : icount.classList?.add('d-none')
  }
}

/**
 * set header logo link
 * @param {HTMLElement} navItemLink dom element
 */
const setHeaderLogoLink = (
  navItemLink
) => {
  let hrefLogo = navItemLink && navItemLink.getAttribute(`href`)
  if (
    hrefLogo
  ) {
    hrefLogo = hrefLogo.replace(`home.html`, `web/send-money/start`)
    navItemLink.setAttribute(`href`, hrefLogo)

  }
}

/**
 * set profile link
 * @param {HTMLElement} navItemLinkForProfile dom element
 */
const setProfileIconLink = (
  navItemLinkForProfile
) => {
  const {
    countryCode,
    langCode
  } = getCountryCodeAndLanguage()
  if (
    navItemLinkForProfile
  ) {
    navItemLinkForProfile.href = `/${countryCode}/${langCode}/account/app/profile`
  }
}

/**
 * set Western Union Prepaid Link in hamburger menu
 * @param {HTMLElement} navItemLinkForWesternUnionPrepaid dom element
 */
const setWuPrepaidLinkInHamburger = (
  navItemLinkForWuPrepaid
) => {
  if (
    navItemLinkForWuPrepaid && sessionStorage.getItem('prepaidToken')
  ) {
    navItemLinkForWuPrepaid.href =  getWuPrepaidLink() ? `${getWuPrepaidLink()}SSO?Token=${sessionStorage.getItem('prepaidToken')}` : `${navItemLinkForWuPrepaid.href}SSO?Token=${sessionStorage.getItem('prepaidToken')}`
  }
}

/**
 * logged in state management and username  display function
 */
const loginHelper = () => {
  const isEmailVerificationRequired = JSON.parse(window.sessionStorage.getItem(`C1124`))
  const dingmobile = document.getElementById(`ding_topup-nav-item`)
  dingmobile && dingmobile.addEventListener('click', (e) => {
    e.preventDefault()
    handleListItemClick(e)
  })
  if (
    window.sessionStorage.AccountNumber && !isEmailVerificationRequired
  ) {
    const userFirstName = window.sessionStorage.FirstName ?? 'Hello'
    const userLastName = window.sessionStorage.LastName ?? 'User'
    const firstName = decodeURIComponent(encodeURIComponent(userFirstName))
    const lastName = decodeURIComponent(encodeURIComponent(userLastName))
    if (
      firstName
    ) {
      const loginNavItem = document.getElementById(`login-nav-item`)
      const mobileLogInRegisterSection = document.getElementById(`mobileLogInRegisterSection`)
      const mobileLoggedInSection = document.getElementById(`mobileLoggedInSection`)
      const navItemLink = document.querySelector(`.wu-header__logo-container .nav-item__link`) || document.querySelector(`.wu-logo`)
      const profileName = document.getElementById(`profileName`)
      const profileNameMobile = document.getElementById(`profileNameMobile`)
      const signUpNavItem = document.getElementById(`sign-up-nav-item`)
      const userLoggedInNavItem = document.getElementById(`user-logged-in-nav-item`)
      const wuUserIconNavItem = document.getElementById(`wu-user-icon-nav-item`)
      const profileIconeLink = document.querySelector('#wu-user-icon-nav-item a')
      const navItemWuPrepaidLink = document.querySelector('a[amplitude-id="menu-prepaid-card"]')
      const fullname = setFullName(firstName, lastName)
      changeHrefForLoggedInState()
      setHeaderLogoLink(navItemLink)
      setProfileIconLink(profileIconeLink)
      navItemWuPrepaidLink && setWuPrepaidLinkInHamburger(navItemWuPrepaidLink)
      signUpNavItem.className = signUpNavItem && signUpNavItem.className.replace(`d-L-block`, `d-none`)
      signUpNavItem.className = signUpNavItem && signUpNavItem.className.replace(`d-L-flex`, `d-none`)
      loginNavItem.className = loginNavItem && loginNavItem.className.replace(`d-L-block`, `d-none`)
      loginNavItem.className = loginNavItem && loginNavItem.className.replace(`d-L-flex`, `d-none`)
      mobileLoggedInSection.className = mobileLoggedInSection && mobileLoggedInSection.className.replace(`d-none`, `d-none d-flex`)
      mobileLogInRegisterSection.className = mobileLogInRegisterSection && mobileLogInRegisterSection.className.replace(`d-flex`, `d-none`)
      wuUserIconNavItem.className = wuUserIconNavItem && wuUserIconNavItem.className.replace(`d-none`, `d-none d-L-block`)
      userLoggedInNavItem.className = userLoggedInNavItem && userLoggedInNavItem.className.replace(`d-none`, `d-none d-L-block`)
      if (profileName) {
        profileName.innerHTML = fullname
      }
      if (profileNameMobile) {
        profileNameMobile.innerHTML = fullname
      }
    }
  }
  document.addEventListener('evergageLogoutMenuCleanup',toggleHideShowMenuItems)
}

export default loginHelper
