
import loadNativeScripts from './services/load-scripts'
import setCookieOptanononSettingConfig from "./utils/footer-helper"
import { getCanonicalUrl } from "./services/utility.service"
import loadNativeStyles from './services/load-styles'

/**
 * Update canonical url for currency-converter pages
 * @returns null value if canonical element doesnt exit
 */
const updateCanonicalUrl = () => {
  const canonicalElem = document.querySelector('link[rel=canonical]')

  if (
    !canonicalElem
  ) {
    return null
  }

  let canonicalUrl = canonicalElem.getAttribute('href')

  canonicalUrl.indexOf('currency-converter') > -1 &&
    canonicalElem.setAttribute('href', getCanonicalUrl(canonicalUrl))
}

/**
 * Header functionality
 */
const headerFunctionalities = () => {
  // Do header stuff
}

/**
 * Footer functionality
 */
const footerFunctionalities = () => {
  setCookieOptanononSettingConfig()
  addFooterMobileMenuContent()
}

/**
 * Load native asseets
 */
const loadAssets = () => {
  loadNativeScripts()
  loadNativeStyles()
}

/**
 * Add mobile content for footer
 */
const addFooterMobileMenuContent = () => {
  const footerMobileMenu = document.querySelector('.wu-footer--landing-page__top__col__sm-accordion')

  if (
    footerMobileMenu &&
    window.pageData.footerMobileMenuContent
  ) {
    footerMobileMenu.innerHTML = window.pageData.footerMobileMenuContent
  }
}

/**
 * Loading assets client-side
 */
const loadNativeAssets = () => {
  updateCanonicalUrl()
  headerFunctionalities()
  footerFunctionalities()

  // window.pageData.folder === 'currency-converter' && setSeoDataBasedOnParam(window.pageData.page)

  window.loaded ? loadAssets() : window.addEventListener('load', loadAssets)
}

export default loadNativeAssets